import React, { useRef, useState, VFC } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import Page from '../../components/Page/Page';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { permConst } from '../../constants/permConst';
import { useCompanyProfileQuery } from '../../hooks/queryHooks';
import { useUserProfile } from '../../hooks/reduxHooks';
import { deletePhysicalOrder, getOrderInfoAndResources, postOrderHeaderAction } from '../../services/ordersService';
import { mapToDisplayedStatus } from '../../utils/orderUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized } from '../../utils/userUtils';
import Step1OrderDetails from './views/Step1OrderDetails';
import Step2Shipping from './views/Step2Shipping';
import Step3PhysicalOrderConfirmation from './views/Step3PhysicalOrderConfirmation';


const STEP_1 = 'step-1-order-info' as const;
const STEP_2 = 'step-2-shipping' as const;
const STEP_3 = 'step-3-confirmation' as const;

const PhysicalOrdersCreate: VFC = () => {
	const userProfile = useUserProfile();
	const { orderHeaderId } = useParams<{ orderHeaderId?: string }>();
	const history = useHistory();
	const [step, setStep] = useState< typeof STEP_1 | typeof STEP_2 | typeof STEP_3 | null >(orderHeaderId ? null : STEP_1);
	const [openModal, setOpenModal] = useState<{ type?: 'DELETE_MODAL' | 'CANCEL_MODAL' }>({});

	const saveCallRef = useRef<Function | undefined>();

	const companyProfileQuery = useCompanyProfileQuery();
	const companyProfile = companyProfileQuery.data?.data?.company_information;
	const orderAndResourcesQuery = useQuery(
		['getOrderInfoAndResources', orderHeaderId],
		() => getOrderInfoAndResources(orderHeaderId as string),
		{
			enabled: !!orderHeaderId,
			onSuccess: (response) => {
				if (!step) {
					setStep(STEP_1);
				}
			},
		},
	);
	const orderData = orderAndResourcesQuery.data?.data.physical_order;
	const isEDIOrder = orderData?.edi_order_flag || false;

	const orderIsCancellable =
		orderData?.order_status === 'REJECTED' &&
		isAuthorized(userProfile.permissions, [
			permConst.PHYSICAL_ORDER.EDIT.COMPANY,
		]);
	const orderIsDeletable =
		orderData?.order_status === 'DRAFT' &&
		isAuthorized(userProfile.permissions, [
			permConst.PHYSICAL_ORDER.EDIT.COMPANY,
		]);

	const saveOrder = () => {
		typeof saveCallRef.current === 'function' && saveCallRef.current();
	};

	const commonProps = {
		orderHeaderId,
		onCancelOrder:
			orderIsCancellable
				? () => { setOpenModal({ type: 'CANCEL_MODAL' }); }
				: undefined,
		onDeleteOrder:
			orderIsDeletable
				? () => { setOpenModal({ type: 'DELETE_MODAL' }); }
				: undefined,
		setSaveCall: (fn: () => void) => saveCallRef.current = fn,
	};

	const orderStatus = (orderData && mapToDisplayedStatus(orderData, userProfile)) || 'DRAFT';

	const orderRejectComment =
		orderData?.order_status === 'REJECTED' && orderData?.order_comments['REJECTION']?.comment;

	const isFault =
		orderAndResourcesQuery.isError &&
		!((orderAndResourcesQuery.error as any).response?.status === 409);

	return (
		<>
			<Page fault={(isFault && orderAndResourcesQuery.error) || companyProfileQuery.error}>
				{step === STEP_1 && isEDIOrder && (
					<Alert variant="warning">
						<b>Transmitted by EDI:</b> editing functions are limited for this order. A
						re-transmittal is required to modify locked fields.
					</Alert>
				)}
				{orderRejectComment && (
					<Alert variant="danger">
						This order was rejected with the the following comment: "
						{orderRejectComment}"
					</Alert>
				)}
				<Breadcrumb>
					<Breadcrumb.Item to="/orders/physical">Physical Orders</Breadcrumb.Item>
					<Breadcrumb.Item active>Create New Physical Order</Breadcrumb.Item>
				</Breadcrumb>
				<Title
					subtitle={
						step === STEP_1
							? 'Step 1: Order Info and Products'
							: step === STEP_2
							? 'Step 2: Shipping'
							: step === STEP_3
							? 'Step 3: Order Confirmation'
							: ''
					}
					status={
						<StatusText
							badge
							variant={orderStatus === 'REJECTED' ? 'warning' : undefined}
						>
							{step && orderStatus}
						</StatusText>
					}
					button={
						<MeatballDropdown>
							{(step === 'step-1-order-info' || step === 'step-2-shipping') && (
								<MeatballDropdown.Item onClick={() => saveOrder()}>
									Save Order
								</MeatballDropdown.Item>
							)}
							<MeatballDropdown.Divider />
							{orderIsDeletable && (
								<MeatballDropdown.Item
									onClick={() => setOpenModal({ type: 'DELETE_MODAL' })}
								>
									<span className="text-danger">Delete Order</span>
								</MeatballDropdown.Item>
							)}
							{orderIsCancellable && (
								<MeatballDropdown.Item
									onClick={() => setOpenModal({ type: 'CANCEL_MODAL' })}
								>
									<span className="text-danger">Cancel Order</span>
								</MeatballDropdown.Item>
							)}
						</MeatballDropdown>
					}
				>
					Create New Physical Order
				</Title>
				{orderAndResourcesQuery.isError ? (
					<Page.FullPageCol>
						<Alert variant="danger" className="alert-icon">
							This order has been submitted and is not editable at this time.
						</Alert>
						<div className="display-flex mt-2">
							<Button variant="outline-secondary" href="/orders/physical">
								View Physical Orders index <FAIcon name="chevron-right" />
							</Button>
							<Button
								variant="outline-secondary"
								href={`/orders/physical/${orderHeaderId}`}
							>
								View details of this order <FAIcon name="chevron-right" />
							</Button>
							<Button variant="outline-secondary" href="/orders/physical/create">
								Create a new order <FAIcon name="chevron-right" />
							</Button>
						</div>
					</Page.FullPageCol>
				) : (
					companyProfile &&
					(step === STEP_2 ? (
						<Step2Shipping
							onPrevious={() => setStep(STEP_1)}
							onNext={() => setStep(STEP_3)}
							{...commonProps}
						/>
					) : step === STEP_3 ? (
						<Step3PhysicalOrderConfirmation
							onPrevious={() => setStep(STEP_2)}
							{...commonProps}
						/>
					) : (
						<Step1OrderDetails
							onNewId={(orderHeaderId: string) => {
								orderHeaderId &&
									history.replace(`/orders/physical/${orderHeaderId}/create`);
							}}
							onNext={() => setStep(STEP_2)}
							companyProfile={companyProfile}
							{...commonProps}
						/>
					))
				)}
			</Page>
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'CANCEL_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await postOrderHeaderAction(orderHeaderId, { action: 'CANCEL' });
								toasterNotify('Order canceled', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'canceling order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								history.push(`/orders/physical/${orderHeaderId}`);
							}
						}
					}}
					title="Cancel Order"
					deleteLabel="Cancel order"
					message="Are you sure you want to cancel this order?"
				/>
			)}
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'DELETE_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await deletePhysicalOrder(orderHeaderId);
								toasterNotify('Order deleted', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'deleting order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								history.push('/orders/physical');
							}
						}
					}}
					title="Delete Order"
					deleteLabel="Delete"
					message="Are you sure you want to delete this order?"
				/>
			)}
		</>
	);
};

export default PhysicalOrdersCreate;
