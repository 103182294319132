import api from './baseService';

export const getPlatforms = () => {
	return api().get<PlatformData[]>('/pdb/platforms');
};

interface SunsetFeatureData {
	display_product_message: {
		from: string;
		until: string;
		message_text: string;
	};
	display_order_message: {
		from: string;
		until: string;
		message_text: string;
	};
	create_physical_product: {
		until: string;
	};
	create_product: {
		until: string;
	};
	create_physical_submission: Record<PlatformCode, { until: string }>;
	edit_product: Record<PlatformCode, { until: string }>;
	edit_part_sku: Record<PlatformCode, { until: string }>;
	upload_packaging_assets: Record<PlatformCode, { until: string }>;
}

export const getSunsetFeatures = () => {
	return api().get<SunsetFeatureData>('/pdb/features/sunset');
};
