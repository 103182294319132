import React, { VFC } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { companyAgreementConstants } from '../../../constants/companyAgreementConstants';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { platformCodes, platformFeatures } from '../../../constants/platformConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { DigitalOrderData } from '../../../services/digitalCodesService';
import { validateActiveAgreementType } from '../../../utils/companyUtils';
import { isInvalid } from '../../../utils/dataUtils';
import { getPermissions } from '../../../utils/digitalCodesUtils';
import { isFeatureActiveForPlatform } from '../../../utils/userUtils';
import { isUploadEligible, modalIdentifiers } from '../DigitalCodesDetail.helpers';

const {
	DRAFT,
	READY_FOR_REVIEW,
	READY_FOR_APPROVAL,
	REJECTED,
	PAYMENT_PENDING,
	PAYMENT_RECEIVED,
	UPLOAD_IN_PROGRESS,
	UPLOAD_FAILED,
	CODES_AVAILABLE,
	COMPLETE,
	AWAITING_PAYMENT_INFO,
} = digitalCodesConstants.status;

// modal identifiers
const {
	CANCEL_ORDER_MODAL,
	DELETE_ORDER_MODAL,
	REVIEWER_APPROVAL_MODAL,
	APPROVER_CONFIRM_APPROVAL_MODAL,
	CANCEL_UPLOAD_MODAL,
	COMPLETE_ORDER_MODAL,
	REJECT_REASON_MODAL,
	RESEND_ORDER_MODAL,
	ASPERA_UPLOAD_CODES_MODAL,
	MANAGE_ACTIVATION_MODAL,
	RETRACT_CODES_MODAL,
} = modalIdentifiers;

interface DigitalCodesButtonsProps {
	showModal: (type: ValuesOf<typeof modalIdentifiers>) => void;
	orderData?: DigitalOrderData;
	agreements?: CompanyAgreement[];
}
const DigitalCodesButtons: VFC<DigitalCodesButtonsProps> = ({
	showModal,
	orderData,
	agreements,
}) => {
	const { status, orderID, isCodesFileAvailable, platform_code, sales_flow_version } = orderData || {};
	const userProfile = useUserProfile();
	const {
		canReview,
		canUploadCodes,
		canDownloadCodes,
		canApprove,
		canCancelOrder,
		canCreateOrder,
		canCompleteOrder,
		canDeleteOrder,
		canManageActivation,
		canRetractCodes,
	} = getPermissions(userProfile);
	const isPlatformActive = (platform_code && isFeatureActiveForPlatform(
		userProfile,
		platformFeatures.DIGITAL_CODES,
		platform_code,
		'edit',
	)) ?? false;

	const buttons = [];

	const isPublisher = userProfile.companyId === orderData?.NDID;
	const productIsUnderTransfer =
		isInvalid(orderData?.productID)?.code === 'DIGITAL_TRANSFER_IN_PROCESS';

	// Reviewer approval button
	canReview &&
	isPlatformActive &&
		[READY_FOR_REVIEW].includes(status as string) &&
		buttons.push(
			<Button
				key="approve-reviewer-button"
				data-testid="approve-reviewer"
				variant="success"
				onClick={() => showModal(REVIEWER_APPROVAL_MODAL)}
				disabled={productIsUnderTransfer}
			>
				Approve
			</Button>,
		);

	// Approver approval button
	canApprove &&
		isPlatformActive &&
		[READY_FOR_APPROVAL].includes(status as string) &&
		buttons.push(
			<Button
				key="approve-approver-button"
				data-testid="approve-approver"
				variant="success"
				onClick={() => showModal(APPROVER_CONFIRM_APPROVAL_MODAL)}
				disabled={productIsUnderTransfer}
			>
				Approve
			</Button>,
		);

	// Reviewer/Approver reject button
	((canReview && status === READY_FOR_REVIEW) || (canApprove && status === READY_FOR_APPROVAL)) &&
		isPlatformActive &&
		buttons.push(
			<Button
				key="reject-button"
				variant="danger"
				onClick={() => showModal(REJECT_REASON_MODAL)}
				disabled={productIsUnderTransfer}
			>
				Reject
			</Button>,
		);

	// Resend order to EBS button
	canReview &&
		isPlatformActive &&
		[PAYMENT_PENDING].includes(status as string) &&
		orderData &&
		!orderData.ebsSalesOrderNum &&
		buttons.push(
			<Button
				key="resend-button"
				variant="primary"
				onClick={() => showModal(RESEND_ORDER_MODAL)}
			>
				Resend Order
			</Button>,
		);

	// cancel in progress upload
	canUploadCodes &&
		isPlatformActive &&
		[UPLOAD_IN_PROGRESS].includes(status as string) &&
		buttons.push(
			<Button
				key="cancel-upload-button"
				variant="danger"
				onClick={() => showModal(CANCEL_UPLOAD_MODAL)}
			>
				Cancel Upload
			</Button>,
		);

	// retract code that have been uploaded
	canRetractCodes &&
		isPlatformActive &&
		[CODES_AVAILABLE, COMPLETE].includes(status as string) &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="retract-upload-button"
				variant="danger"
				onClick={() => showModal(RETRACT_CODES_MODAL)}
			>
				Retract Upload
			</Button>,
		);

	// delete order
	canDeleteOrder &&
		isPlatformActive &&
		status === DRAFT &&
		sales_flow_version === 2 &&
		isPublisher &&
		buttons.push(
			<Button
				key="cancel-order-button"
				variant="danger"
				onClick={() => showModal(DELETE_ORDER_MODAL)}
			>
				Delete Order
			</Button>,
		);

	// cancel order
	canCancelOrder &&
		isPlatformActive &&
		([READY_FOR_REVIEW, READY_FOR_APPROVAL, AWAITING_PAYMENT_INFO].includes(status as string) ||
			(sales_flow_version === 2 && status === REJECTED)) &&
		isPublisher &&
		buttons.push(
			<Button
				key="cancel-order-button"
				variant="danger"
				onClick={() => showModal(CANCEL_ORDER_MODAL)}
			>
				Cancel Order
			</Button>,
		);

	// complete
	canCompleteOrder &&
		isPlatformActive &&
		[PAYMENT_RECEIVED].includes(status as string) &&
		orderData &&
		!orderData.is_upload_eligible &&
		buttons.push(
			<Button
				key="complete-order-button"
				variant="primary"
				onClick={() => showModal(COMPLETE_ORDER_MODAL)}
			>
				Complete Order
			</Button>,
		);

	// manage activation
	canManageActivation &&
		isPlatformActive &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="manage-activation-button"
				variant={orderData?.activation_date ? 'outline-secondary' : 'primary'}
				onClick={() => showModal(MANAGE_ACTIVATION_MODAL)}
			>
				Manage Activation
			</Button>,
		);

	// upload codes
	canUploadCodes &&
		isPlatformActive &&
		[PAYMENT_RECEIVED, UPLOAD_FAILED].includes(status as string) &&
		isUploadEligible(orderData) &&
		buttons.push(
			<Button
				key="upload-codes-button"
				variant="primary"
				onClick={() => showModal(ASPERA_UPLOAD_CODES_MODAL)}
			>
				Upload Codes
			</Button>,
		);

	// download codes (allows if platform is disabled)
	canDownloadCodes &&
		[CODES_AVAILABLE, COMPLETE].includes(status as string) &&
		isPublisher &&
		!!isCodesFileAvailable &&
		buttons.push(
			<Button
				key="download-codes-button"
				variant="primary"
				href={`https://${global.config.apiHost}${global.config.apiBasePath}/dc/v1_1/orders/${orderID}/codes`}
			>
				Download Codes
			</Button>,
		);

	// edit order
	canCreateOrder &&
		isPlatformActive &&
		(status === DRAFT || (status === REJECTED && sales_flow_version === 2)) &&
		isPublisher &&
		validateActiveAgreementType(
			agreements || [],
			companyAgreementConstants.TYPE.DIGITAL,
			platformCodes['Nintendo Switch'],
			true,
		) &&
		buttons.push(
			<Button
				as={Link}
				to={`/orders/digital-codes/${orderID}/create`}
				key="edit-order-button"
				variant="primary"
				disabled={productIsUnderTransfer}
			>
				Edit Order
			</Button>,
		);

	return <>{buttons}</>;
};

export default DigitalCodesButtons;
