export const platformConstants = {
	NINTENDO_SWITCH: 'Nintendo Switch',
	NEW_3DS_EXCLUSIVE: 'New 3DS Exclusive',
	NINTENDO_3DS: 'Nintendo 3DS',
	WII_U: 'Wii U',
	WII: 'Wii',
} as const;

export const platformCodes = {
	'Nintendo Switch': 'HAC',
	'New 3DS Exclusive': 'KTR',
	'Nintendo 3DS': 'CTR',
	'Wii U': 'WUP',
	'Wii': 'RVL',
} as const;

export const platformNames = {
	HAC: 'Nintendo Switch',
	KTR: 'New 3DS Exclusive',
	CTR: 'Nintendo 3DS',
	WUP: 'Wii U',
	RVL: 'Wii',
} as const;

export const platformTypeRelations = {
	'CTR-B': {
		product_description: 'Kiosks Demo',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-E': {
		product_description: 'Kiosks Demo - Interactive',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-H': {
		product_description: 'Kiosks Demo - Video',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-M': {
		product_description: 'AOC',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'AOC',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-N': {
		product_description: 'Digital',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-T': {
		product_description: 'Downloadable Demo',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-U': {
		product_description: 'Patch',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'PATCH',
		distribution_type: 'DIGITAL'
	} as const,
	'CTR-P': {
		product_description: 'Physical',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'PHYSICAL'
	} as const,
	'KTR-B': {
		product_description: 'Kiosks Demo',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'KTR-M': {
		product_description: 'AOC',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'AOC',
		distribution_type: 'DIGITAL'
	} as const,
	'KTR-N': {
		product_description: 'Digital',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'KTR-T': {
		product_description: 'Downloadable Demo',
		platform: platformNames.CTR,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'KTR-U': {
		product_description: 'Patch',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'PATCH',
		distribution_type: 'DIGITAL'
	} as const,
	'KTR-P': {
		product_description: 'Physical',
		platform: platformNames.CTR,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'PHYSICAL'
	} as const,
	'WUP-B': {
		product_description: 'Kiosks Demo',
		platform: platformNames.WUP,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'WUP-M': {
		product_description: 'AOC',
		platform: platformNames.WUP,
		product_type: 'FULL',
		release_type: 'AOC',
		distribution_type: 'DIGITAL'
	} as const,
	'WUP-N': {
		product_description: 'Digital',
		platform: platformNames.WUP,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'WUP-T': {
		product_description: 'Downloadable Demo',
		platform: platformNames.WUP,
		product_type: 'DEMO',
		release_type: 'FIRST',
		distribution_type: 'DIGITAL'
	} as const,
	'WUP-U': {
		product_description: 'Patch',
		platform: platformNames.WUP,
		product_type: 'FULL',
		release_type: 'PATCH',
		distribution_type: 'DIGITAL'
	} as const,
	'WUP-P': {
		product_description: 'Physical',
		platform: platformNames.WUP,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'PHYSICAL'
	} as const,
	'RVL-P': {
		product_description: 'Nintendo Wii',
		platform: platformNames.RVL,
		product_type: 'FULL',
		release_type: 'FIRST',
		distribution_type: 'PHYSICAL'
	} as const,
} as const;

export const platformFeatures = {
	AGREEMENTS: 'agreements',
	CODE_IN_BOX: 'code_in_box',
	DIGITAL_CODES:'digital_codes',
	DISTRIBUTION_TRANSFERS: 'distribution_transfers',
	MARKETING_ASSETS: 'marketing_assets',
	PACKAGING_ASSETS: 'packaging_assets',
	PHYSICAL_ORDER: 'physical_order',
	PRODUCTS: 'products',
	PROJECTIONS: 'projections',
} as const;
