import React, { Dispatch, ReactElement, SetStateAction, useState, VFC } from 'react';
import { Alert, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import ActionConfirmationModal from '../../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import CommentModal from '../../../components/modals/CommentModal/CommentModal';
import DeleteConfirmationModal from '../../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import StatusText from '../../../components/StatusText/StatusText';
import { digitalCodesConstants } from '../../../constants/digitalCodesConstants';
import { platformFeatures } from '../../../constants/platformConstants';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { postDPRequestAction } from '../../../services/digitalCodesService';
import { RootState } from '../../../store/store';
import { formatCurrency } from '../../../utils/currencyUtils';
import { dateFormat, formatDate } from '../../../utils/dateUtils';
import {
	getCurrentRequest,
	getStatusForPriceRequestAction
} from '../../../utils/digitalCodesUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isFeatureActiveForPlatform } from '../../../utils/userUtils';
import DeclineModal from './DeclineModal';
import ExtendLimitModal from './ExtendLimitModal';
import {
	getActivePriceRequest,
	getDPPermissions,
	getEffectiveDateForPriceRequest,
	getInProcessPriceRequest,
	isExtendLimitDisabled
} from './helpers';
import RequestActivityLog from './RequestActivityLog';
import RequestDeclaredPriceAgreementModal from './RequestDeclaredPriceAgreementModal';

const { APPROVED } = digitalCodesConstants.priceRequestActions;
const { PENDING_REVIEW, REVIEWED } = digitalCodesConstants.priceRequestStatuses;

interface DPRequestsModalModel {
	type?:
		| 'AGREEMENT_MODAL'
		| 'NEW_AGREEMENT_MODAL'
		| 'APPROVE_MODAL'
		| 'DECLINE_MODAL'
		| 'RETRACT_MODAL'
		| 'EXTEND_LIMIT_MODAL'
		| 'COMMENT_MODAL';
	data?: DeclaredPriceRequestData;
	componentData?: ProductComponentData;
	dpRequestsData?: DeclaredPriceRequestData[];
	isSubmitting?: boolean;
}

interface RequestDetailProps {
	heading?: string;
	dpRequest?: DeclaredPriceRequestData;
	readOnly?: boolean;
	canApproverApprove?: boolean;
	canReviewerApprove?: boolean;
	canApproverDecline?: boolean;
	canReviewerDecline?: boolean;
	canRetract?: boolean;
	canComment?: boolean;
	canExtendLimit?: boolean;
	setOpenModal?: Dispatch<SetStateAction<DPRequestsModalModel>>;
	component?: Record<string, any>;
	isInternal?: boolean;
}

const RequestDetail: VFC<RequestDetailProps> = ({
	heading,
	dpRequest,
	readOnly,
	canApproverApprove,
	canReviewerApprove,
	canApproverDecline,
	canReviewerDecline,
	canRetract,
	canComment,
	setOpenModal,
	isInternal,
}: RequestDetailProps): ReactElement => {
	const canBeReviewerApproved = dpRequest?.request_status === PENDING_REVIEW;
	const canBeApproverApproved = dpRequest?.request_status === REVIEWED;
	const effectiveDate = dpRequest && getEffectiveDateForPriceRequest(dpRequest);
	const status = getStatusForPriceRequestAction(dpRequest, isInternal);
	return (
		<>
			<SectionTitle>{heading}</SectionTitle>
			{setOpenModal && (
				<ButtonToolbar className="mb-3">
					<ButtonGroup>
						<Button
							variant="outline-secondary"
							onClick={() =>
								setOpenModal({ type: 'AGREEMENT_MODAL', data: dpRequest })
							}
						>
							View Declared Price Agreement
						</Button>
						{canComment && (
							<>
								<Button
									variant="outline-secondary"
									disabled={readOnly}
									onClick={() =>
										setOpenModal({ type: 'COMMENT_MODAL', data: dpRequest })
									}
								>
									Comment
								</Button>{' '}
							</>
						)}{' '}
					</ButtonGroup>{' '}
					{((canReviewerApprove && canBeReviewerApproved) ||
						(canApproverApprove && canBeApproverApproved)) && (
						<Button
							variant="success"
							disabled={readOnly}
							onClick={() => setOpenModal({ type: 'APPROVE_MODAL', data: dpRequest })}
						>
							Approve
						</Button>
					)}{' '}
					{((canReviewerDecline && canBeReviewerApproved) ||
						(canApproverDecline && canBeApproverApproved)) && (
						<Button
							variant="danger"
							disabled={readOnly}
							onClick={() => setOpenModal({ type: 'DECLINE_MODAL', data: dpRequest })}
						>
							Decline
						</Button>
					)}{' '}
					{canRetract && dpRequest?.retractable && (
						<>
							<Button
								variant="danger"
								disabled={readOnly}
								onClick={() =>
									setOpenModal({ type: 'RETRACT_MODAL', data: dpRequest })
								}
							>
								Retract
							</Button>{' '}
						</>
					)}
				</ButtonToolbar>
			)}

			<PropertyDisplay label="Request Status">
				<StatusText
					variant={
						['PENDING_REVIEW', 'REVIEWED'].includes(status as string)
							? 'warning'
							: undefined
					}
				>
					{status}
				</StatusText>
			</PropertyDisplay>
			<PropertyDisplay label="Date Requested">
				{dpRequest?.submitted_datetime != null ? (
					formatDate(dpRequest?.submitted_datetime, dateFormat.DATE)
				) : (
					<>&mdash;</>
				)}
			</PropertyDisplay>
			{effectiveDate && (
				<PropertyDisplay label="Effective Date">
					{formatDate(effectiveDate, dateFormat.DATE)}
				</PropertyDisplay>
			)}
			<PropertyDisplay label="Price (USD)">
				{dpRequest?.certified_price != null ? (
					formatCurrency(dpRequest?.certified_price)
				) : (
					<>&mdash;</>
				)}
			</PropertyDisplay>
		</>
	);
};

interface DeclaredPriceTabProps {
	show?: boolean;
	nsUid?: string;
	dpRequests?: (DeclaredPriceRequestData & Required<Pick<DeclaredPriceRequestData, 'actions'>>)[];
	canCreateDeclaredPriceRequest?: boolean;
	platformCode?: PlatformCode;
	component?: ProductComponentData;
	reloadComponentData: () => void;
	readOnly?: boolean;
	rightsHolderId?: string;
	users?: Record<string, any>[];
	companies?: Record<string, any>[];
}

const DeclaredPriceTab: VFC<DeclaredPriceTabProps> = ({
	show,
	nsUid,
	dpRequests,
	canCreateDeclaredPriceRequest,
	platformCode,
	component,
	reloadComponentData,
	readOnly,
	rightsHolderId,
	users,
	companies,
}: DeclaredPriceTabProps): ReactElement => {
	const platforms = useSelector((store: RootState) => store.referenceReducer.platforms?.content);
	const userProfile: UserProfile = useUserProfile();
	const [openModal, setOpenModal] = useState<DPRequestsModalModel>({});

	const inProcessRequest =
		dpRequests && (getInProcessPriceRequest(dpRequests) as DeclaredPriceRequestData);
	const activeRequest =
		dpRequests && (getActivePriceRequest(dpRequests) as DeclaredPriceRequestData);

	const gameName = component?.game_name;

	const {
		hasInternalPermission,
		canReviewerApprove,
		canApproverApprove,
		canRetract,
		canAgree,
		canComment,
		canExtendLimit,
	} = getDPPermissions(userProfile);
	const isFeatureEnabled = platformCode != null && isFeatureActiveForPlatform(
		userProfile,
		platformFeatures.DIGITAL_CODES,
		platformCode,
		'edit',
	);

	const showNewRequestButton =
		isFeatureEnabled &&
		!hasInternalPermission &&
		canAgree &&
		component?.retail_prices.length === 0;
	const showExtendLimitButton =
		isFeatureEnabled &&
		canExtendLimit &&
		(!!activeRequest ||
			(dpRequests && getCurrentRequest(dpRequests)?.action_type === 'TRANSFERRED'));

	return (
		<>
			{ component?.retail_prices?.length ? <>
				<Alert variant="info">The price has been determined. The declared price feature is no longer available.</Alert>
			</> : <>
				{(showNewRequestButton || showExtendLimitButton) && (
					<ButtonToolbar>
						{showNewRequestButton && (
							<Button
								variant="primary"
								disabled={
									readOnly ||
									!component ||
									!dpRequests ||
									!rightsHolderId ||
									!canCreateDeclaredPriceRequest
								}
								onClick={() => setOpenModal({ type: 'NEW_AGREEMENT_MODAL' })}
							>
								Request New Declared Price Agreement
							</Button>
						)}
						{showExtendLimitButton && (
							<Button
								variant="outline-secondary"
								disabled={
									readOnly ||
									!dpRequests ||
									!rightsHolderId ||
									isExtendLimitDisabled(dpRequests, rightsHolderId)
								}
								onClick={() =>
									setOpenModal({
										type: 'EXTEND_LIMIT_MODAL',
										componentData: component,
									})
								}
							>
								Allow New Declared Price Agreement
							</Button>
						)}
					</ButtonToolbar>
				)}

				{inProcessRequest && (
					<RequestDetail
						dpRequest={inProcessRequest}
						heading="In Process"
						setOpenModal={setOpenModal}
						canApproverApprove={isFeatureEnabled && canApproverApprove}
						canReviewerApprove={isFeatureEnabled && canReviewerApprove}
						canApproverDecline={canApproverApprove}
						canReviewerDecline={canReviewerApprove}
						canComment={isFeatureEnabled && canComment}
						canRetract={canRetract}
						readOnly={readOnly}
					/>
				)}
				{activeRequest && (
					<RequestDetail
						dpRequest={activeRequest}
						heading="Active"
						setOpenModal={setOpenModal}
						canComment={isFeatureEnabled && canComment}
						readOnly={readOnly}
					/>
				)}
			</>}

			<SectionTitle>Activity Log</SectionTitle>
			{dpRequests?.length ? (
				<div className="mx-3">
					<RequestActivityLog
						requests={dpRequests}
						users={users}
						companies={companies}
						showDetailsCallBack={(requestId: number) => {
							const data = dpRequests?.find(
								(request) => request.declared_price_request_id === requestId,
							);
							setOpenModal({ type: 'AGREEMENT_MODAL', data });
						}}
					/>

				</div>
			) : (
				<Alert variant="warning">No declared price activity exists on this component</Alert>
			)}
			<ActionConfirmationModal
				show={openModal?.type === 'APPROVE_MODAL'}
				isSubmitting={openModal?.isSubmitting}
				onCancel={() => setOpenModal({})}
				onConfirm={async () => {
					setOpenModal({ ...openModal, isSubmitting: true } as DPRequestsModalModel);

					const dpRequest = openModal.data;
					if (dpRequest) {
						try {
							await postDPRequestAction(
								String(dpRequest?.declared_price_request_id),
								{
									action: APPROVED,
								},
							);
							toasterNotify(
								`Status has been updated to the declared price request for "${gameName}"`,
								'info',
							);
							setOpenModal({});
						} catch (error: unknown) {
							error instanceof Error &&
								toasterNotify(
									createMessageForError(
										error,
										'approving the declared price request',
									),
									'error',
									error,
								);
							setOpenModal({ ...openModal, isSubmitting: false });
						} finally {
							reloadComponentData();
						}
					}
				}}
				title="Approve Declared Price Request"
				confirmLabel="Approve"
				cancelLabel="Cancel"
			>
				<Alert variant="info">
					Do you wish to approve this declared price request for "{gameName}"?
				</Alert>
			</ActionConfirmationModal>
			<DeclineModal
				show={openModal?.type === 'DECLINE_MODAL'}
				onClose={() => setOpenModal({})}
				onChange={() => reloadComponentData()}
				gameName={gameName}
				currentRequest={openModal?.data || undefined}
			/>
			<DeleteConfirmationModal
				show={openModal?.type === 'RETRACT_MODAL'}
				closeModal={() => setOpenModal({})}
				confirmDelete={async () => {
					setOpenModal({ ...openModal, isSubmitting: true });
					const currentDPRequest = openModal.data;
					if (!currentDPRequest) {
						return;
					}
					try {
						await postDPRequestAction(currentDPRequest.declared_price_request_id, {
							action: digitalCodesConstants.priceRequestActions.RETRACTED,
						});
						toasterNotify(
							`Your declared price request for "${gameName}" has been retracted`,
							'info',
						);
						setOpenModal({});
					} catch (error) {
						toasterNotify(
							error instanceof Error
								? createMessageForError(
										error,
										'retracting the declared price request',
								  )
								: '',
							'error',
							(error as Error) || undefined,
						);
						setOpenModal({ ...openModal, isSubmitting: false });
					} finally {
						reloadComponentData();
					}
				}}
				title="Retract Declared Price Request"
				message={`Do you wish to retract the currently in process declared price request for "${gameName}"?`}
				deleteLabel={'Retract'}
				submissionMessage={undefined}
				lastUpdated={undefined}
			/>
			<RequestDeclaredPriceAgreementModal
				show={
					openModal?.type === 'AGREEMENT_MODAL' ||
					openModal?.type === 'NEW_AGREEMENT_MODAL'
				}
				closeModal={() => setOpenModal({})}
				readOnly={openModal?.type === 'AGREEMENT_MODAL' || undefined}
				requestId={
					openModal?.type === 'AGREEMENT_MODAL'
						? openModal?.data?.declared_price_request_id
						: undefined
				}
				productPlatformCode={
					openModal?.type === 'NEW_AGREEMENT_MODAL' ? platformCode : undefined
				}
				nsUid={openModal?.type === 'NEW_AGREEMENT_MODAL' ? nsUid : undefined}
				defaultValues={
					openModal?.type === 'NEW_AGREEMENT_MODAL'
						? {
								ns_uid: nsUid,
								company_name: userProfile.companyName,
								component_game_name: component?.game_name,
								product_game_code: component?.game_code,
								platform_name: platforms.find(
									(platform: any) => platform.platform_code === platformCode,
								).platform_name,
						  }
						: {}
				}
				onSubmitted={() => {
					reloadComponentData();
				}}
				componentGameName={gameName}
			/>
			<CommentModal
				show={openModal?.type === 'COMMENT_MODAL'}
				title="Add Comment on Declared Price Request"
				prompt=""
				onClose={() => setOpenModal({})}
				onChange={async (comment) => {
					try {
						if (openModal?.data?.declared_price_request_id) {
							await postDPRequestAction(openModal.data.declared_price_request_id, {
								action: 'COMMENT',
								comment,
							});
							setOpenModal({});
							toasterNotify('Comment posted on activity log', 'success');
						}
					} catch (error: unknown) {
						error instanceof Error &&
							toasterNotify(
								createMessageForError(
									error,
									'posting the comment on the declared price request',
								),
								'error',
								error,
							);
						setOpenModal({ ...openModal });
					} finally {
						reloadComponentData();
					}
				}}
			/>
			<ExtendLimitModal
				show={openModal?.type === 'EXTEND_LIMIT_MODAL'}
				onClose={() => setOpenModal({})}
				onChange={() => reloadComponentData()}
				component={openModal?.componentData || undefined}
				priceRequests={dpRequests}
				rightsHolderId={rightsHolderId}
			/>
		</>
	);
};

export default DeclaredPriceTab;
