import React, { ReactElement, VFC, useState } from 'react';
import { Alert, Tab, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Page from '../../components/Page/Page';
import Panel from '../../components/Panel/Panel';
import PropertyDisplay from '../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { permConst } from '../../constants/permConst';
import { platformFeatures } from '../../constants/platformConstants';
import { transferRightsConstants } from '../../constants/transferRightsConstants';
import { useReferenceData, useReferenceDataMeta, useUserProfile } from '../../hooks/reduxHooks';
import { getCompaniesInBulk, getCompanyAgreements } from '../../services/companiesService';
import { getComponentDetails } from '../../services/digitalCodesService';
import { getUsersInBulk } from '../../services/usersService';
import { formatCurrency } from '../../utils/currencyUtils';
import { compareDates, dateFormat, formatDate } from '../../utils/dateUtils';
import { determineActiveRightTransfer } from '../../utils/transferRightsUtils';
import { isAuthorized, isFeatureActiveForPlatform } from '../../utils/userUtils';
import { getAllUserAndCompanyIDs } from './ComponentDetail.helpers';
import DeclaredPriceTab from './views/DeclaredPriceTab';
import WholesalePriceRequestsTab from './views/WholesalePriceRequestsTab';


const ComponentDetail: VFC<unknown> = (): ReactElement => {
	const match = useParams<{ nsUid: string }>();
	const { nsUid }: { nsUid: string } = match;

	const userProfile: UserProfile = useUserProfile();
	const platforms: PlatformData[] = useReferenceData('platforms');
	const platformsReady = useReferenceDataMeta('platforms')?.status === 'COMPLETED';

	const [selectedTab, setSelectedTab] = useState<string | null>(null);

	const componentQuery = useQuery(['getComponentDetail', nsUid], () =>
		getComponentDetails(nsUid),
	);
	const componentData = {...componentQuery?.data?.data};
	const platformCode = componentData.product?.platform_code
	;
	const [allUserIDs, allCompanyIDs] = getAllUserAndCompanyIDs(componentData);
	const token = componentQuery.isSuccess &&
		!componentQuery.isFetching &&
		(Date.now() - componentQuery.dataUpdatedAt < 60000) && // token expires in 1 min
		componentQuery.data.headers['x-pdb-authorization'];

	const usersQuery = useQuery(
		['getUsersInBulk', ...allUserIDs],
		() =>
			getUsersInBulk(
				allUserIDs,
				['user_name', 'login_id'],
				token,
			),
		{ enabled: !!token && !!allUserIDs.length, staleTime: Infinity },
	);
	const users = usersQuery.data?.data;

	const companiesQuery = useQuery(
		['getCompanyInBulk', ...allCompanyIDs],
		() =>
			getCompaniesInBulk(
				allCompanyIDs,
				['company_name'],
				token,
			),
		{ enabled: !!token && !!allCompanyIDs.length, staleTime: Infinity },
	);
	const companies = companiesQuery.data?.data;

	const hasInternalPermission = isAuthorized(userProfile.permissions, [
		permConst.PRODUCT.VIEW.ALL.THIRD,
	]);
	const isFeatureEnabledForWSP =  platformCode != null && isFeatureActiveForPlatform(
		userProfile,
		platformFeatures.CODE_IN_BOX,
		platformCode,
		'view',
	);

	const digitalNDIDCompanyId = componentData?.product?.product_rights?.find(
		(rh) => rh.right_type === transferRightsConstants.DISTRIBUTION_TYPE.DIGITAL,
	)?.ndid_company_id;
	const ownerNDIDCompanyId = componentData?.product?.product_rights?.find(
		(rh) => rh.right_type === transferRightsConstants.DISTRIBUTION_TYPE.OWNERSHIP,
	)?.ndid_company_id;
	const isDigitalPub = userProfile.companyId === digitalNDIDCompanyId;
	const isOwnerOnly =
		userProfile.companyId !== digitalNDIDCompanyId &&
		userProfile.companyId === ownerNDIDCompanyId;
		
	const agreementsQuery = useQuery(
		['getCompanyAgreements', digitalNDIDCompanyId],
		() => getCompanyAgreements(digitalNDIDCompanyId),
		{ enabled: !!(digitalNDIDCompanyId && (hasInternalPermission || isDigitalPub))}
	);

	const areAgreementsOk = (agreements?: CompanyAgreement[]) => {
		const getAgreementFamily = (platformCode?: string) =>
			platformCode &&
			platforms?.find((platform) =>
				platform.platform_code === platformCode
			)?.agreement_family;
		return agreements?.some(
			(agreement) =>
				agreement.agreementType === companyAgreementConstants.TYPE.DIGITAL &&
				getAgreementFamily(componentData?.product?.platform_code) ===
					agreement.platformDevelopmentCode &&
				(!agreement.expirationDate || compareDates(agreement.expirationDate) > 0) &&
				[
					companyAgreementConstants.STATUS.EXECUTED,
					companyAgreementConstants.STATUS.IN_NEGOTIATION,
				].includes(agreement.status),
		);
	};

	const showDeclaredPriceTab = !!componentData?.declared_price_requests;
	const showWholesalePriceRequestsTab = isFeatureEnabledForWSP && !!componentData?.wholesale_price_requests;
	const hasDuplicatePrices = Number(componentData?.component?.retail_prices?.length) > 1;

	const showTabs = showDeclaredPriceTab || showWholesalePriceRequestsTab || hasDuplicatePrices;

	const transferPending =
		componentData?.product && 
		'active_rights_transfer' in componentData.product &&
		determineActiveRightTransfer(componentData.product, 'priceRequest');
	const agreementsOk =
		(hasInternalPermission || isDigitalPub) &&
		areAgreementsOk(agreementsQuery.data?.data);

	return (
		<Page
			isLoading={
				componentQuery.isLoading ||
				agreementsQuery.isLoading ||
				usersQuery.isLoading ||
				companiesQuery.isLoading ||
				!platformsReady
			}
			fault={componentQuery.isError}
			documentTitle={componentData?.component?.game_name}
		>
			{!hasInternalPermission &&
				!isOwnerOnly &&
				!agreementsOk &&
				(agreementsQuery.isSuccess ? (
					<Alert variant="danger">
						The Global Digital agreement has expired. Please renew the agreement in the
						Nintendo Developer Portal to proceed.
					</Alert>
				) : (
					<Alert variant="danger">
						Agreements could not be retrieved and functions that require a valid
						agreement have been disabled. Reload or contact site support for assistance.
					</Alert>
				))}
			{transferPending && (
				<Alert variant="danger">
					The product is pending a rights transfer. Some actions are disabled until the
					transfer is completed or rescinded.
				</Alert>
			)}
			<Breadcrumb>
				<Breadcrumb.Item to="/products">Products</Breadcrumb.Item>
				<Breadcrumb.Item
					to={`/products/${componentData?.product?.product_id}?tab=digital-catalog`}
				>
					{componentData?.product?.name} ({componentData?.product?.game_code})
				</Breadcrumb.Item>
				<Breadcrumb.Item active>{componentData?.component?.game_name}</Breadcrumb.Item>
			</Breadcrumb>
			<Title>{componentData?.component?.game_name}</Title>
			{showTabs ? (
				<Page.SplitPage>
					<Page.MainCol cols={9}>
						<StatefulTabs onSelect={(newTab) => setSelectedTab(newTab)} retainPageState>
							{showWholesalePriceRequestsTab && (
								<Tab
									title="Wholesale Price Requests"
									eventKey="wholesale-price-requests"
								>
									<Page.ContentContainer>
										<WholesalePriceRequestsTab
											show={selectedTab === 'wholesale-price-requests'}
											token={token}
											wspRequests={componentData?.wholesale_price_requests}
											canCreateWholesalePriceRequest={
												componentData?.can_create_wholesale_price_request
											}
											reloadComponentData={() => componentQuery.refetch()}
											platformCode={componentData?.product?.platform_code}
											component={componentData?.component}
											readOnly={transferPending || !agreementsOk}
											nsUid={nsUid}
											rightsHolderId={digitalNDIDCompanyId}
											users={users}
											companies={companies}
										/>
									</Page.ContentContainer>
								</Tab>
							)}
							{showDeclaredPriceTab && (
								<Tab
									title="Declared Price Requests"
									eventKey="declared-price-requests"
								>
									<Page.ContentContainer>
										<DeclaredPriceTab
											show={selectedTab === 'declared-price-requests'}
											nsUid={nsUid}
											dpRequests={componentData?.declared_price_requests}
											reloadComponentData={() => componentQuery.refetch()}
											component={componentData?.component}
											canCreateDeclaredPriceRequest={
												componentData?.can_create_declared_price_request
											}
											platformCode={componentData?.product?.platform_code}
											readOnly={transferPending || !agreementsOk}
											rightsHolderId={digitalNDIDCompanyId}
											users={users}
											companies={companies}
										/>
									</Page.ContentContainer>
								</Tab>
							)}
							{hasDuplicatePrices && (
								<Tab title="Duplicate Prices" eventKey="duplicate-price">
									<Page.ContentContainer>
										<PropertyDisplay label="Available Digital Item" noStripes>
											{componentData?.component?.game_name}
										</PropertyDisplay>
										<PropertyDisplay label="NSUID" noStripes>
											{componentData?.component?.ns_uid}
										</PropertyDisplay>
										<Table className="base-title-table" striped>
											<thead>
												<tr>
													<th>Start Date</th>
													<th className="col-sm-5">Price</th>
												</tr>
											</thead>
											<tbody>
												{componentData?.component?.retail_prices.map(
													({ price, start_datetime }, index) => (
														<tr key={index + '_price'}>
															<td>
																{start_datetime
																	? formatDate(
																			start_datetime,
																			dateFormat.DATE,
																	  )
																	: ''}
															</td>
															<td className="col-sm-5">
																{price ? formatCurrency(price) : ''}
															</td>
														</tr>
													),
												)}
											</tbody>
										</Table>
										<p>
											Duplicate prices must be resolved before codes can be
											ordered.
										</p>
									</Page.ContentContainer>
								</Tab>
							)}
						</StatefulTabs>
					</Page.MainCol>
					<Page.SidebarCol cols={3}>
						<Panel>
							<Panel.Body>
								<PropertyDisplay label="Publisher" compact>
									{componentData?.product?.company_name}
								</PropertyDisplay>
								<PropertyDisplay label="Product" compact>
									{componentData?.product?.name} (
									{componentData?.product?.game_code})
								</PropertyDisplay>
								<PropertyDisplay label="Component Name" compact>
									{componentData?.component?.game_name}
								</PropertyDisplay>
								<PropertyDisplay label="NSUID" compact>
									{componentData?.component?.ns_uid}
								</PropertyDisplay>
								<PropertyDisplay label="Component Game Code" compact>
									{componentData?.component?.game_code}
								</PropertyDisplay>
								<PropertyDisplay label="Component Item Code" compact>
									{componentData?.component?.item_code || <>&mdash;</>}
								</PropertyDisplay>
								<PropertyDisplay label="Content Type" compact>
									{componentData?.component?.content_type}
								</PropertyDisplay>
								<PropertyDisplay label="Bundle Type" compact>
									{componentData?.component?.bundle_type}
								</PropertyDisplay>
								<PropertyDisplay label="Retail Price" compact>
									{(Number(componentData?.component?.retail_prices?.length) > 1
										? 'Duplicate'
										: componentData?.component?.retail_prices?.[0]?.price !=
										  null
										? formatCurrency(
												componentData?.component?.retail_prices?.[0]?.price,
										  )
										: null) || <>&mdash;</>}
								</PropertyDisplay>
							</Panel.Body>
						</Panel>
					</Page.SidebarCol>
				</Page.SplitPage>
			) : (
				<Page.FullPageCol>
					<SectionTitle>Component Information</SectionTitle>
					<PropertyDisplay label="Publisher">
						{componentData?.product?.company_name}
					</PropertyDisplay>
					<PropertyDisplay label="Product">
						{componentData?.product?.name} ({componentData?.product?.game_code})
					</PropertyDisplay>
					<PropertyDisplay label="Component Name">
						{componentData?.component?.game_name}
					</PropertyDisplay>
					<PropertyDisplay label="NSUID">
						{componentData?.component?.ns_uid}
					</PropertyDisplay>
					<PropertyDisplay label="Component Game Code">
						{componentData?.component?.game_code}
					</PropertyDisplay>
					<PropertyDisplay label="Component Item Code">
						{componentData?.component?.item_code || <>&mdash;</>}
					</PropertyDisplay>
					<PropertyDisplay label="Content Type">
						{componentData?.component?.content_type}
					</PropertyDisplay>
					<PropertyDisplay label="Bundle Type">
						{componentData?.component?.bundle_type}
					</PropertyDisplay>
					<PropertyDisplay label="Retail Price">
						{(Number(componentData?.component?.retail_prices?.length) > 1
							? 'Duplicate'
							: componentData?.component?.retail_prices?.[0]?.price != null
							? formatCurrency(componentData?.component?.retail_prices?.[0]?.price)
							: null) || <>&mdash;</>}
					</PropertyDisplay>
				</Page.FullPageCol>
			)}
		</Page>
	);
};
export default ComponentDetail;
