import React from 'react';

import { orderConst } from '../../constants/orderConstants';
import { platformCodes, platformFeatures, platformNames } from '../../constants/platformConstants';
import { OrderDetailData, PhysicalOrderIndexData } from '../../services/ordersService';
import { isAuthorized, isFeatureActiveForPlatform, isFeatureActiveOnAnyPlatform } from '../../utils/userUtils';
import { Link } from 'react-router-dom';
import FAIcon from '../../components/FAIcon/FAIcon';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { formatCurrency } from '../../utils/currencyUtils';
import StatusText from '../../components/StatusText/StatusText';
import { linkColumn } from '../../components/BaseTable/BaseTable';
import { displayString } from '../../utils/stringUtils';
import { permConst } from '../../constants/permConst';
import { mapToDisplayedStatus } from '../../utils/orderUtils';


export const getPlatformsForFilter = (userProfile: UserProfile, platformActive: boolean) => {
	const codes = Object.values(platformCodes);
	return codes
		.filter((code) => { 
			if (
				isFeatureActiveForPlatform(
					userProfile,
					platformFeatures.PHYSICAL_ORDER,
					code,
					'view',
				)
			) {
				const editable = isFeatureActiveForPlatform(
					userProfile,
					platformFeatures.PHYSICAL_ORDER,
					code,
					'edit',
				);
				return (editable ? 1 : -1) * (platformActive ? 1 : -1) === 1;
			}
			return null;
		})
		.map((code) => platformNames[code]);
};

export const getPermissions = (userProfile: UserProfile) => ({
	 canCreateOrders:isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.EDIT.COMPANY,
	]) && isFeatureActiveOnAnyPlatform(userProfile, platformFeatures.PHYSICAL_ORDER, 'edit'),
	 canViewAllOrders:isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.VIEW.ALL,
	]),
	 canReviewOrders:isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.REVIEW.ALL,
	]),
	 canApproveOrders:isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.APPROVE.ALL,
	]),
});

export const getDataFormat = (
	userProfile: UserProfile,
) => {
	const { canCreateOrders, canViewAllOrders } = getPermissions(userProfile);
	const getStatus = (orderData?: Pick<OrderDetailData, 'order_status' | 'order_errors'>) => 
		mapToDisplayedStatus(orderData, userProfile) || null;


	const ChevronLink = ({ original }: { original: PhysicalOrderIndexData }) => {
		if (
			!isFeatureActiveForPlatform(
				userProfile,
				platformFeatures.PHYSICAL_ORDER,
				original.platform_code,
				'view',
			)
		) {
			return null;
		}
		const orderStatus = original.order_status.toUpperCase();
		const reviewOrder =
			!isFeatureActiveForPlatform(
				userProfile,
				platformFeatures.PHYSICAL_ORDER,
				original.platform_code,
				'edit',
			) ||
			(orderStatus === orderConst.STATUS.DRAFT.toUpperCase() && !canCreateOrders) ||
			orderStatus !== orderConst.STATUS.DRAFT.toUpperCase();
	

		if (reviewOrder) {
			return (
				<Link
					to={`${
						original.sales_flow_version === 2 ? '/orders/physical/' : '/orders/review/'
					}${original.order_header_id}`}
				>
					<FAIcon name="chevron-right" className="text-primary" />
				</Link>
			);
		} else {
			return (
				<Link
					to={`${original.sales_flow_version === 2 ? '/orders/physical/' : '/orders/'}${
						original.order_header_id
					}/create`}
				>
					<FAIcon name="chevron-right" className="text-primary" />
				</Link>
			);
		}
	};

	return [
		{
			Header: 'Publisher',
			accessor: 'publisher',
			show: canViewAllOrders,
		},
		{
			Header: 'Platform',
			accessor: 'platform',
		},
		{
			Header: 'Publisher PO',
			accessor: 'publisher_po',
		},
		{
			Header: 'Sales Order',
			accessor: 'noa_sales_order_number',
			show: canViewAllOrders,
		},
		{
			Header: 'Req Number',
			accessor: 'req_number',
			show: canViewAllOrders,
		},
		{
			Header: 'NOA PO',
			accessor: 'noa_po',
			show: canViewAllOrders,
		},
		{
			Header: 'Date Submitted',
			accessor: 'submission_date',
			sortMethod: (a: string | number | Date, b: string | number | Date) => {
				return new Date(a) > new Date(b) ? 1 : -1;
			},
			Cell: (cell: { value: string }) =>
				formatDate(parseDateString(cell.value), dateFormat.DATE),
		},
		{
			Header: 'Last Modified',
			accessor: 'modification_date',
			width: 110,
			sortMethod: (a: string | number | Date, b: string | number | Date) => {
				return new Date(a) > new Date(b) ? 1 : -1;
			},
			Cell: (cell: { value: string }) =>
				formatDate(parseDateString(cell.value), dateFormat.DATE),
			show: canViewAllOrders,
		},
		{
			Header: 'Quoted Price',
			id: 'total_price',
			width: 116,
			className: 'text-right',
			headerClassName: 'text-right',
			accessor: (row: Record<string, any>) => parseInt(row.total_price, 10),
			Cell: (cell: { value: string | number }) =>
				cell.value ? formatCurrency(cell.value, 0) : '',
		},
		{
			Header: 'Status',
			accessor: 'displayStatus',
			width: 160,
			Cell: (cell: { original: OrderDetailData; value: string }) =>
				cell.value ?
					<StatusText
						variant={cell.value === 'REJECTED' ? 'warning' : undefined}
					>
						{getStatus(cell.original)}
					</StatusText>
					: '',
		},
		{
			...linkColumn,
			Cell: ChevronLink,
		},
	];
};

export const getFilterProperties = (userProfile: UserProfile, platformActive?: boolean) => {
	const { canReviewOrders, canApproveOrders, canViewAllOrders } = getPermissions(userProfile);

	const platformsForFilter = getPlatformsForFilter(userProfile, !!platformActive);
	const filterProperties = new Map();
	if (platformsForFilter.length  > 1) {
		filterProperties.set('Platform', {
			filter: 'platform',
			selectableFilters: new Set(
				platformsForFilter,
			),
			selectedFilters: new Set() as Set<string>,
		});
	}
	filterProperties.set('Status', {
		filter: 'displayStatus',
		selectedFilters: new Set([
			...(canReviewOrders && platformActive ? [
				displayString(orderConst.STATUS.SUBMITTED_FOR_REVIEW)
			]: []),
			...(canApproveOrders && platformActive ? [
				displayString(orderConst.STATUS.SUBMITTED_FOR_APPROVAL),
				displayString(orderConst.STATUS.ORDER_APPROVED),
				displayString(orderConst.STATUS.ERROR),
			]: []),
			...((canReviewOrders || canApproveOrders) && !platformActive ? [
				displayString(orderConst.STATUS.COMPLETE)
			] : []),
		]),
		selectableFilters: new Set([
			displayString(orderConst.STATUS.DRAFT),
			displayString(orderConst.STATUS.SUBMITTED_FOR_REVIEW),
			...(canViewAllOrders ? [displayString(orderConst.STATUS.SUBMITTED_FOR_APPROVAL)]: []),
			displayString(orderConst.STATUS.ORDER_PENDING),
			...(canViewAllOrders ? [displayString(orderConst.STATUS.ORDER_APPROVED)]: []),
			displayString(orderConst.STATUS.AWAITING_PAYMENT),
			displayString(orderConst.STATUS.IN_MANUFACTURING),
			displayString(orderConst.STATUS.PRODUCTION_PLANNING),
			displayString(orderConst.STATUS.COMPLETE),
			displayString(orderConst.STATUS.REJECTED),
			displayString(orderConst.STATUS.CANCELLED),
			...(canViewAllOrders ? [displayString(orderConst.STATUS.ERROR)]: []),
		]),
		customEvaluation: (status: string, filters: Set<string>) => {
			let userHasAttribute = false,
				statusValue = displayString(status);
			filters.forEach((filter) => {
				if (!canViewAllOrders && filter === orderConst.STATUS.ORDER_PENDING) {
					userHasAttribute =
						userHasAttribute ||
						statusValue === orderConst.STATUS.ERROR ||
						statusValue === orderConst.STATUS.ORDER_APPROVED ||
						statusValue === orderConst.STATUS.AWAITING_PAYMENT;
				} else if (
					!canViewAllOrders &&
					filter === orderConst.STATUS.SUBMITTED_FOR_REVIEW
				) {
					userHasAttribute =
						userHasAttribute ||
						statusValue === orderConst.STATUS.SUBMITTED_FOR_APPROVAL;
				} else {
					userHasAttribute = userHasAttribute || statusValue === filter;
				}
			});
			return userHasAttribute;
		},
	});
	return filterProperties;
};
