import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { setTabState, setUIState } from '../store/actions/actions';
import { getLocation } from '../utils/routeUtils';
import { RootState } from '../store/store';
import { isFeatureSunsetted } from '../utils/sunsetFeaturesUtils';

export const useUserProfile = () => {
	return useSelector((state: RootState) => state.authReducer.userProfile);
};

export const useCompanyProfile = () => {
	return useSelector((state: RootState) => state.referenceReducer.companyProfile.content);
};

export const useUiStates = (tab?: boolean) => {
	const routeMatch = useRouteMatch();
	const dispatch = useDispatch();
	const uiStates = useSelector((state: RootState) => state.uiReducer.uiStates);
	const searchParams = new URLSearchParams(getLocation().search);

	let tabParam = (!!tab && searchParams.has('tab')) ? searchParams.get('tab') : '';
	const id = `${routeMatch.path}${tab ? '?tab=' + tabParam : ''}`;
	const get = (altId?: string) => (uiStates && uiStates[altId || id]) || {};
	const push = (name: string, value?: string | null) => dispatch(setUIState(id, name, value));
	return { get, push };
};

export const useTabStates = () => {
	const { path } = useRouteMatch();
	const dispatch = useDispatch();
	const tabStates = useSelector((state: RootState) => state.uiReducer.tabStates);

	const get = () => (tabStates && tabStates[path]) || null;
	const set = (tab: string) => dispatch(setTabState(path, tab));
	return { get, set };
};

export const useIsFeatureSunsetted = (featureName: string, platform?: PlatformCode) => {
	const sunsetData = useSelector<RootState>((state) => state.referenceReducer.sunsetFeatures) as Record<'content' | 'meta', any>;
	if (sunsetData?.content) {
		return isFeatureSunsetted(sunsetData.content as SunsetTable, featureName, platform);
	}
	return false;
};

export const useReferenceData = (dataType: string): any => 
	useSelector((store: RootState) => store.referenceReducer[dataType]?.content);

export const useReferenceDataMeta = (dataType: string) => 
	useSelector((store: RootState) => store.referenceReducer[dataType]?.meta);
