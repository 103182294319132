import React, { Component } from 'react';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../../components/FAIcon/FAIcon';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import PropertyDisplay from '../../../components/PropertyDisplay/PropertyDisplay';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Title from '../../../components/Title/Title';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { loadingConstants } from '../../../constants/loadingConstants';
import { permConst } from '../../../constants/permConst';
import { platformFeatures } from '../../../constants/platformConstants';
import { transferRightsConstants } from '../../../constants/transferRightsConstants';
import { getProductRightsTransferRequest } from '../../../services/productTransfersService';
import { dateFormat, formatDate, parseDateString } from '../../../utils/dateUtils';
import { displayString } from '../../../utils/stringUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';
import { isAuthorized, isFeatureActiveForPlatform } from '../../../utils/userUtils';
import NotFound from '../../NotFound/NotFound';
import { SubmissionAsset } from '../../SubmissionDetails/utilComponents/SubmissionAsset';
import AcceptDistributionTransferModal from './modals/AcceptDistributionTransferModal';
import RejectDistributionTransferModal from './modals/RejectDistributionTransferModal';
import { getDistribution } from './ProductDistributionTransferTable';

import '../ProductRights.css';


function mapStateToProps(state, ownProps) {
	return {
		userProfile: state.authReducer.userProfile,
		platforms: state.referenceReducer.platforms?.content,
		platformsReady: state.referenceReducer.platforms?.meta.status === loadingConstants.COMPLETED
	};
}

const title = 'Distribution Transfer Details';

export class ProductDistributionTransfer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			productRightsTransferId: props.match.params.id,
			isValidRequest: false,
			modalType: null,
			showModal: false,
			transferFiles: []
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.updateTransferStatus = this.updateTransferStatus.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		if (!this.props.platformsReady) {
			return;
		}
		this.loadDistributionRightsRequestDetails();
		this.componentDidUpdate = null;
	}

	updateTransferStatus(status) {
		const { transferRequest } = this.state;
		transferRequest.transfer_status = status;
		this.setState({
			transferRequest: transferRequest
		});
	}

	renderModal() {
		const { userProfile } = this.props;
		const { productRightsTransferId, transferRequest } = this.state;

		const transferStatus =
			transferRequest.transfer_status ===
			transferRightsConstants.REQUEST_STATUS.REVIEWED
				? transferRightsConstants.REQUEST_STATUS.PROCESSED
				: transferRightsConstants.REQUEST_STATUS.REVIEWED;

		switch (this.state.modalType) {
			case 'accept-transfer':
				return (
					<AcceptDistributionTransferModal
						userProfile={userProfile}
						productRightsTransferId={productRightsTransferId}
						closeModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
						newTransferStatus={transferStatus}
					/>
				);
			case 'reject-transfer':
				return (
					<RejectDistributionTransferModal
						userProfile={userProfile}
						productRightsTransferId={productRightsTransferId}
						closeModal={this.toggleModal}
						updateTransferStatus={this.updateTransferStatus}
					/>
				);
			default:
				toasterNotify(
					`An unexpected error occurred opening modal ${this.state.modalType}`
				);
		}
	}

	toggleModal(type, transferId = null, loadResources = false) {
		if (this.state.showModal === true) {
			type = null;
		}
		this.setState(
			{
				showModal: !this.state.showModal,
				modalType: type,
				selectedTransferId: transferId
			},
			() => {
				if (loadResources) {
					this.loadResources();
				}
			}
		);
	}

	loadDistributionRightsRequestDetails() {
		getProductRightsTransferRequest(this.state.productRightsTransferId)
			.then((response) => {
				this.setState({
					transferRequest: response.data,
					isValidRequest: this.validatePLA(response.data),
					transferFiles:
						response.data.transfer_agreement_files != null
							? response.data.transfer_agreement_files
							: []
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving distribution rights requests'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({
					isLoading: false
				});
			});
	}

	hasFileIssues() {
		const { transferFiles } = this.state;
		return transferFiles && !!transferFiles.find(item => (
			item.status === fileTransferConst.TRANSFER_STATES.FAILED ||
			item.status === fileTransferConst.TRANSFER_STATES.INFECTED
		));
	}

	canApprove() {
		const { userProfile } = this.props;
		const { transferRequest } = this.state;

		if (this.hasFileIssues()) {
			return false;
		}

		const validOrderReviewer =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.REVIEW.ALL_DISTRIBUTION
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING;

		const validOrderApprover =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.PROCESS.ALL_DISTRIBUTION
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;

		return validOrderApprover || validOrderReviewer;
	}

	canReject() {
		const { userProfile } = this.props;
		const { transferRequest } = this.state;

		const validOrderReviewerReject =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.REVIEW_REJECT.ALL_DISTRIBUTION
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.PENDING;

		const validOrderApproverReject =
			isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.PROCESS_REJECT.ALL_DISTRIBUTION
			]) &&
			transferRequest.transfer_status ===
				transferRightsConstants.REQUEST_STATUS.REVIEWED;

		return validOrderApproverReject || validOrderReviewerReject;
	}

	renderTitleButton() {
		const { userProfile } = this.props;
		const { isValidRequest, isLoading, transferRequest } = this.state;

		if (
			!isFeatureActiveForPlatform(
				userProfile,
				platformFeatures.DISTRIBUTION_TRANSFERS,
				transferRequest.product_platform_code,
			)
		) {
			return null;
		}
		
		const displayApproveButton =
			isValidRequest && !isLoading && this.canApprove();
		const displayRejectButton = this.canReject();

		return (
			<>
				{displayApproveButton && (
					<Button
						type="button"
						variant="success"
						id="accept-transfer"
						onClick={(e) => {
							this.toggleModal('accept-transfer');
						}}
					>
						Accept Transfer
					</Button>
				)}
				{displayRejectButton && (
					<Button
						type="button"
						variant="danger"
						id="reject-transfer"
						onClick={(e) => {
							this.toggleModal('reject-transfer');
						}}
					>
						Reject Transfer
					</Button>
				)}
			</>
		);
	}

	renderFooterButton() {
		return (
			<div className="btn-container">
				<Row>
					<Col>
						<Button
							variant="outline-primary"
							id="back-to-index"
							onClick={() =>
								this.props.history.push(
									'/admin/product-distribution-transfer-requests'
								)
							}
						>
							<FAIcon name="chevron-left" className="mr-1" />
							Back to Distribution Rights Transfers
						</Button>
					</Col>
				</Row>
			</div>
		);
	}

	validatePLA(transferRequest) {
		if (
			transferRequest &&
			transferRequest.digital_pub_flag &&
			transferRequest.transfer_to_company_invalid_digital_agreements_flag
		) {
			return false;
		}

		if (
			transferRequest &&
			transferRequest.retail_pub_flag &&
			transferRequest.transfer_to_company_invalid_retail_agreements_flag
		) {
			return false;
		}
		return true;
	}

	render() {
		const {
			transferRequest,
			isLoading,
			isValidRequest,
			transferFiles
		} = this.state;
		const { userProfile, platforms, platformsReady } = this.props;
		const transferStatus = transferRequest
			? transferRequest.transfer_status
			: '';
		const classType =
			transferRequest && transferRequest.transfer_status !== 'Rejected'
				? 'status-info'
				: 'status-danger';
		const titleStatus = {
			text: (transferStatus && displayString(transferStatus)) || '',
			class: classType
		};
		const platformName = !isLoading && platformsReady && transferRequest &&
			(platforms.find(p => transferRequest.product_platform_code === p.platform_code) || {}).platform_name;

		if (
			!isAuthorized(userProfile.permissions, [
				permConst.PRODUCT.RIGHT_TRANSFER.VIEW.ALL_DISTRIBUTION,
			]) || (!isLoading && !transferRequest)
		) {
			return <NotFound />;
		}

		return (
			<Page>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Row>
							<Col sm={12}>
								{this.hasFileIssues() ? (
									<Alert variant="danger">
										The file uploaded for this transfer is damaged or failed a
										virus scan. This transfer can't be accepted and a new
										transfer will need to be initiated by the publisher.
									</Alert>
								) : !isValidRequest ? (
									<Alert variant="danger">
										Receiving Publisher does not have needed PLA to support this
										transfer
									</Alert>
								) : (
									<Alert variant="success">Valid PLA Confirmed</Alert>
								)}
							</Col>
						</Row>

						<Breadcrumb>
							<Breadcrumb.Item to="/admin/product-distribution-transfer-requests/">
								Transfer Product Distribution
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						</Breadcrumb>
						<Title
							title={title}
							status={titleStatus}
							button={this.renderTitleButton()}
						/>

						<Page.FullPageCol>
							<Row>
								<Col sm={12}>
									<SectionTitle>Game Info</SectionTitle>
									<PropertyDisplay label="Request Date">
										{formatDate(
											parseDateString(transferRequest.creation_date),
											dateFormat.DATE_YMD,
										)}
									</PropertyDisplay>
									<PropertyDisplay label="Title">
										{transferRequest.product_game_name}
									</PropertyDisplay>
									<PropertyDisplay label="Platform">
										{platformName}
									</PropertyDisplay>
									<PropertyDisplay label="Game Code">
										{transferRequest.product_game_code}
									</PropertyDisplay>
									<PropertyDisplay label="Distribution">
										{getDistribution(transferRequest)}
									</PropertyDisplay>
									<SectionTitle>Initiating Publisher</SectionTitle>
									<PropertyDisplay label="Publisher">
										{transferRequest.transfer_from_company}
									</PropertyDisplay>
									<PropertyDisplay label="Company Admin">
										{transferRequest.transfer_from_company_requester}
									</PropertyDisplay>
									<SectionTitle>Receiving Publisher</SectionTitle>

									<PropertyDisplay label="Publisher">
										{transferRequest.transfer_to_company}
									</PropertyDisplay>
									<PropertyDisplay label="Company Admin">
										{transferRequest.transfer_to_company_accepter}
									</PropertyDisplay>
									<SectionTitle>Transfer Docs</SectionTitle>

									<Table className="table-transfer-docs">
										<thead>
											<tr>
												<th>Filename</th>
												<th>Uploaded</th>
											</tr>
										</thead>
										<tbody>
											{transferFiles.map((item, i) => (
												<tr key={`transfer-asset-${i}`}>
													<td>
														<SubmissionAsset
															asset={item}
															hideDate={true}
															userProfile={this.props.userProfile}
														/>
													</td>
													<td>
														{formatDate(
															parseDateString(item.uploaded_date),
															dateFormat.DATE_YMD,
														)}
														{item.status ===
															fileTransferConst.TRANSFER_STATES
																.FAILED && ' (UPLOAD FAILED)'}
														{item.status ===
															fileTransferConst.TRANSFER_STATES
																.INFECTED && ' (VIRUS SCAN FAILED)'}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{this.renderFooterButton()}
								</Col>
							</Row>
						</Page.FullPageCol>
					</>
				)}
				{this.state.showModal && this.state.modalType ? this.renderModal() : null}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(ProductDistributionTransfer);
