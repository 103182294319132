import React, { useState, VFC } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQueries, useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import ActionConfirmationModal from '../../components/modals/ActionConfirmationModal/ActionConfirmationModal';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import Page from '../../components/Page/Page';
import PhysicalOrder from '../../components/PhysicalOrder/PhysicalOrder';
import ProFormaInvoice from '../../components/ProFormaInvoice/ProFormaInvoice';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { orderConst } from '../../constants/orderConstants';
import { permConst } from '../../constants/permConst';
import { platformFeatures } from '../../constants/platformConstants';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getCompanyProfile } from '../../services/companiesService';
import {
	deletePhysicalOrder,
	getNewOrderProductPrice,
	getPhysicalOrder,
	postOrderHeaderAction,
} from '../../services/ordersService';
import { getPossiblyInvalidValue } from '../../utils/dataUtils';
import { dateFormat, formatDate } from '../../utils/dateUtils';
import {
	doesOrderHaveInvalidValues,
	getTotalFromOrder,
	makeInvoiceFileName,
	mapToDisplayedStatus,
} from '../../utils/orderUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized, isFeatureActiveForPlatform } from '../../utils/userUtils';
import PhysicalOrderCommentModal from './modals/PhysicalOrderCommentModal';
import RejectModal from './modals/RejectModal';

import './PhysicalOrdersDetail.css';

interface ModalTypes {
	type?:
		| 'REJECT_MODAL'
		| 'APPROVE_MODAL'
		| 'COMMENT_MODAL'
		| 'CANCEL_MODAL'
		| 'DELETE_MODAL';
}

const PhysicalOrdersDetail: VFC = () => {
	const history = useHistory();
	const userProfile = useUserProfile();
	const { orderHeaderId } = useParams<{ orderHeaderId: string }>();
	const [openModal, setOpenModal] = useState<ModalTypes>();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const orderQuery = useQuery(['getPhysicalOrder', orderHeaderId], () =>
		getPhysicalOrder(orderHeaderId),
	);
	const orderData = orderQuery?.data?.data;
	const orderIsInProcess = ['DRAFT', 'SUBMITTED_FOR_REVIEW', 'SUBMITTED_FOR_APPROVAL'].includes(
		String(orderData?.order_status),
	);
	const orderApprovalDate = orderData?.order_status_history.find(
		(entry) => entry.status === 'ORDER_APPROVED',
	)?.status_change_date;
	const enableEBSPriceLookup = !!orderData && orderIsInProcess;
	const isSelfConsignee = orderData?.consignee_company_id === null;
	const companyQuery = useQuery(
		['getCompanyProfile', orderData?.ndid_company_id],
		() => getCompanyProfile(orderData?.ndid_company_id as string),
		{ enabled: !!orderData?.ndid_company_id },
	);
	const priceQueriesConfig =
		enableEBSPriceLookup &&
		orderData?.order_details.map(({ product_id }) => {
			const p = getPossiblyInvalidValue(product_id);
			return {
				queryKey: ['getNewOrderProductPrice', p],
				queryFn: () => getNewOrderProductPrice(String(p)),
			};
		});
	const priceQueries = useQueries(priceQueriesConfig || []);

	const isInternalUser = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.VIEW.ALL,
	]);
	const isApprover = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.APPROVE.ALL,
	]);
	const isReviewer = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.REVIEW.ALL
	]);
	const isPublisher = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.VIEW.COMPANY,
	]);
	const canEditOrder = isAuthorized(userProfile.permissions, [
		permConst.PHYSICAL_ORDER.EDIT.COMPANY,
	]) && userProfile.companyId === orderData?.ndid_company_id;
	const canEditComments =
		(isApprover &&
			['SUBMITTED_FOR_REVIEW', 'SUBMITTED_FOR_APPROVAL'].includes(
				String(orderData?.order_status),
			)
		) || (isReviewer &&
			orderData?.order_status === orderConst.STATUS.SUBMITTED_FOR_REVIEW
		);

	const displayedEBSErrors =
		(isInternalUser && orderData?.order_errors?.length && orderData.order_errors) || null;

	const ebsPrices = priceQueries?.map(q => {
		if (isInternalUser && q.error) {
			return { error: true as const, message: (q.error as any).response?.data?.message?.error?.message as string | undefined };
		}
		return q.data?.data as string;
	});

	const orderRejectComment =
		orderData?.order_status === 'REJECTED' && orderData?.order_comments['REJECTION']?.comment;
	const orderCancelComment =
		orderData?.order_status === 'CANCELLED' && orderData?.order_comments['CANCEL']?.comment;
	const orderWasRejectedComment =
		!!orderData?.order_status &&
		['SUBMITTED_FOR_APPROVAL', 'SUBMITTED_FOR_REVIEW'].includes(orderData.order_status) &&
		orderData?.order_comments['REJECTION']?.comment;

	const orderIsCancellable =
		orderData &&
		canEditOrder &&
		['SUBMITTED_FOR_APPROVAL', 'SUBMITTED_FOR_REVIEW', 'REJECTED'].includes(orderData.order_status);
	const orderIsDeletable = 
		orderData &&
		canEditOrder &&
		['DRAFT'].includes(orderData.order_status);
	const orderIsRejectable =
		(isReviewer && orderData?.order_status === 'SUBMITTED_FOR_REVIEW') ||
		(isApprover && orderData?.order_status === 'SUBMITTED_FOR_APPROVAL');
	const orderIsEditable =
		orderData && 
		canEditOrder &&
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.PHYSICAL_ORDER,
			orderData.platform_code,
			'edit',
		) &&
		isAuthorized(userProfile.permissions, [permConst.PHYSICAL_ORDER.EDIT.COMPANY]) &&
		['DRAFT', 'REJECTED'].includes(orderData.order_status);
	const orderHasInvalidValues =
		(isApprover || isReviewer) &&
		!!(orderData && doesOrderHaveInvalidValues(orderData)) &&
		['SUBMITTED_FOR_APPROVAL', 'SUBMITTED_FOR_REVIEW'].includes(orderData.order_status);

	const orderIsApprovable =
		orderIsRejectable &&
		isFeatureActiveForPlatform(
			userProfile,
			platformFeatures.PHYSICAL_ORDER,
			orderData.platform_code,
			'edit',
		);
	const orderTotal = orderData && getTotalFromOrder(orderData);

	const productIsUnderTransfer = orderData?.order_details.some(
		(item) =>
			typeof item.product_id === 'object' &&
			item.product_id.invalid === true &&
			item.product_id.code === 'RETAIL_RIGHTS_TRANSFER_IN_PROCESS',
	);
	const orderProFormaInvoiceIsAvailable = !!orderData?.noa_sales_order_number;
	const showPONumberReminder = isPublisher && 
		orderData?.order_status === orderConst.STATUS.AWAITING_PAYMENT;
	const showLinkToNewIndex =
		isAuthorized(userProfile.permissions, [
			permConst.PHYSICAL_ORDER.VIEW.ALL,
			permConst.PHYSICAL_ORDER.VIEW.COMPANY,
		]);
	const getSelfConsigneeFormatted = () =>
		orderData?.order_status === 'DRAFT' || orderData?.order_status === 'REJECTED'
			? {
				consignee_company_name:
					companyQuery.data?.data?.company_information.company_name,
				consignee_address_street_line_1:
					companyQuery.data?.data?.company_information.address_1,
				consignee_address_street_line_2:
					companyQuery.data?.data?.company_information.address_2,
				consignee_address_city: companyQuery.data?.data?.company_information.city,
				consignee_address_region: companyQuery.data?.data?.company_information.region,
				consignee_address_postal_code:
					companyQuery.data?.data?.company_information.postal_code,
				consignee_address_country_name:
					companyQuery.data?.data?.company_information.country_name,
				consignee_email_address:
					companyQuery.data?.data?.company_information.consignee_email_address,
				consignee_phone_number:
					companyQuery.data?.data?.company_information.consignee_phone_number,
			  }
			: undefined;

	const isLoading = orderQuery.isLoading || priceQueries?.some((query) => query.isLoading);

	return (
		<Page isLoading={isLoading} fault={orderQuery.error}>
			{displayedEBSErrors && (
				<Alert variant="danger">
					Order errors were reported by EBS/Galaxy. These are only displayed to internal
					users:
					<ul className="mb-0">
						{displayedEBSErrors.map((error, index) => (
							<li key={'error'+index}>{error}</li>
						))}
					</ul>
				</Alert>
			)}
			{orderRejectComment && (
				<Alert variant="danger">
					This order was rejected with the the following comment: "{orderRejectComment}"
				</Alert>
			)}
			{orderWasRejectedComment && (
				<Alert variant="info">
					This order was previously rejected with the the following comment: "
					{orderWasRejectedComment}"
				</Alert>
			)}
			{orderCancelComment && (
				<Alert variant="danger">
					This order was cancelled with the the following comment: "{orderCancelComment}"
				</Alert>
			)}
			{productIsUnderTransfer && (
				<Alert variant="danger">
					This order includes a product with a pending rights transfer. Some actions are
					disabled until the transfer is completed or rescinded.
				</Alert>
			)}
			{orderHasInvalidValues && (
				<Alert variant="danger">
					This order has values that are no longer valid and cannot be approved. The
					submitter can correct errors after the order is rejected.
				</Alert>
			)}
			{showPONumberReminder && (
				<Alert variant="warning">
					Please include the PO number in wire transfer. NOTE: Wire transfer fees are the
					responsibility of the publisher.
				</Alert>
			)}
			<Breadcrumb>
				{showLinkToNewIndex && (
					<Breadcrumb.Item to="/orders/physical">Physical Orders</Breadcrumb.Item>
				)}
				{!showLinkToNewIndex && (
					<Breadcrumb.Item to="/orders">Physical Orders</Breadcrumb.Item>
				)}
				<Breadcrumb.Item active>View Physical Order</Breadcrumb.Item>
			</Breadcrumb>
			<Title
				button={
					<>
						{orderIsCancellable && (
							<Button
								variant="danger"
								onClick={() => setOpenModal({ type: 'CANCEL_MODAL' })}
							>
								Cancel Order
							</Button>
						)}
						{orderIsDeletable && (
							<Button
								variant="danger"
								onClick={() => setOpenModal({ type: 'DELETE_MODAL' })}
							>
								Delete Order
							</Button>
						)}
						{orderIsApprovable && (
							<Button
								variant="success"
								onClick={() => setOpenModal({ type: 'APPROVE_MODAL' })}
								disabled={productIsUnderTransfer || orderHasInvalidValues}
							>
								Approve
							</Button>
						)}
						{orderIsRejectable && (
							<Button
								variant="danger"
								onClick={() => setOpenModal({ type: 'REJECT_MODAL' })}
							>
								Reject
							</Button>
						)}
						{orderIsEditable && (
							<Button
								onClick={() =>
									history.push(`/orders/physical/${orderHeaderId}/create`)
								}
							>
								Edit Order
							</Button>
						)}
						{orderProFormaInvoiceIsAvailable && (
							<ProFormaInvoice
								disabled={!companyQuery.isSuccess}
								companyData={companyQuery.data?.data.company_information}
								invoiceDate={formatDate(
									orderApprovalDate as string,
									dateFormat.DATE_YMD,
								)}
								orderNumber={orderData.noa_sales_order_number}
								poNumber={orderData.publisher_po_number}
								orderDetails={orderData.order_details.map((item) => ({
									...item,
									itemName: `[${item.game_code}] ${item.product_name}`,
									quantity: getPossiblyInvalidValue(item.quantity),
									publisher_part_number: item.publisher_part_number,
								}))}
								isDigitalOrder={false}
								subtotal={orderTotal}
								grandTotal={orderTotal}
								fileName={makeInvoiceFileName(
									orderApprovalDate as string,
									orderData.noa_sales_order_number,
								)}
							/>
						)}
						{canEditComments && (
							<MeatballDropdown>
								<MeatballDropdown.Item
									onClick={() => setOpenModal({ type: 'COMMENT_MODAL' })}
								>
									Edit Comments
								</MeatballDropdown.Item>
							</MeatballDropdown>
						)}
					</>
				}
				status={
					<StatusText
						badge
						variant={orderData?.order_status === 'REJECTED' ? 'warning' : undefined}
					>
						{mapToDisplayedStatus(orderData, userProfile)}
					</StatusText>
				}
			>
				View Physical Order
			</Title>
			<Page.ContentContainer>
				<PhysicalOrder
					orderData={orderData}
					ebsPrices={ebsPrices}
					selfConsignee={isSelfConsignee ? getSelfConsigneeFormatted() : undefined}
					showPartNumbers={
						!!companyQuery.data?.data.company_information.sku_number_required
					}
					isInternalUser={isAuthorized(userProfile.permissions, [
						permConst.PHYSICAL_ORDER.VIEW.ALL,
					])}
				/>
				<div className="btn-container">
					<div className="text-left">
						<Button
							as={Link}
							variant="outline-secondary"
							to={showLinkToNewIndex ? '/orders/physical' : '/orders'}
							disabled={isSubmitting}
						>
							<FAIcon name="chevron-left" className="mr-1" />
							Back to Physical Orders
						</Button>
					</div>
				</div>
			</Page.ContentContainer>
			<RejectModal
				show={openModal?.type === 'REJECT_MODAL'}
				orderHeaderId={orderHeaderId}
				closeModal={() => setOpenModal({})}
			/>
			<ActionConfirmationModal
				show={openModal && openModal.type === 'APPROVE_MODAL'}
				onCancel={() => setOpenModal({})}
				isSubmitting={isSubmitting}
				onConfirm={async () => {
					if (orderHeaderId) {
						try {
							setIsSubmitting(true);
							const approveAction =
								orderData?.order_status === 'SUBMITTED_FOR_APPROVAL'
									? 'APPROVE'
									: 'REVIEW';
							await postOrderHeaderAction(orderHeaderId, { action: approveAction });
							toasterNotify('Order approved', 'success');
						} catch (error: unknown) {
							error instanceof Error &&
								toasterNotify(
									createMessageForError(error, 'approving order'),
									'error',
									error,
								);
						} finally {
							setIsSubmitting(false);
							setOpenModal({});
							history.go(0);
						}
					}
				}}
				title="Approve order"
				confirmLabel="Confirm Approval"
				cancelLabel="Close"
			>
				<Alert className="mb-0" variant="info">
					Approve Order?
				</Alert>
			</ActionConfirmationModal>
			<PhysicalOrderCommentModal
				show={openModal?.type === 'COMMENT_MODAL'}
				orderHeaderId={orderHeaderId}
				orderComments={orderData?.order_comments}
				onClose={() => setOpenModal({})}
				onChange={() => {
					orderQuery.refetch();
					setOpenModal({});
				}}
			/>
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'CANCEL_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await postOrderHeaderAction(orderHeaderId, { action: 'CANCEL' });
								toasterNotify('Order canceled', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'canceling order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								orderQuery.refetch();
							}
						}
					}}
					title="Cancel Order"
					deleteLabel="Cancel order"
					message="Are you sure you want to cancel this order?"
				/>
			)}
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'DELETE_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await deletePhysicalOrder(orderHeaderId);
								toasterNotify('Order deleted', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'deleting order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								history.push('/orders/physical');
							}
						}
					}}
					title="Delete order"
					deleteLabel="Delete"
					message="Are you sure you want to delete this order?"
				/>)}
		</Page>
	);
};

export default PhysicalOrdersDetail;
