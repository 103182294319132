import React, { VFC } from 'react';
import { Button, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getPermissions } from './PhysicalOrdersManagement.helpers';
import OrdersTableTab from './views/OrdersTableTab';

const PhysicalOrdersManagement: VFC = () => {
	const userProfile = useUserProfile();
	const history = useHistory();
	const { canCreateOrders } = getPermissions(userProfile);

	return (
		<Page>
			<Title
				button={
					canCreateOrders ? (
						<Button variant="primary" onClick={() => history.push('/orders/create')}>
							<FAIcon name="plus" className="mr-1" />
							Create Physical Order
						</Button>
					) : undefined
				}
			>
				Physical Orders
			</Title>
			<StatefulTabs retainPageState>
				<Tab title="Active Platforms" eventKey="active">
					<OrdersTableTab eventKey="active" activePlatforms />
				</Tab>
				<Tab title="Legacy Platforms" eventKey="legacy">
					<OrdersTableTab eventKey="legacy" />
				</Tab>
			</StatefulTabs>
		</Page>
	);
};

export default PhysicalOrdersManagement;
